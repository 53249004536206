<div *ngIf="isDataRetrieved; else waitingForData">
  <div class="table-responsive">
    <table class="table table-hover table-bordered align-middle table-sm">
      <thead>
        <tr>
          <th scope="col">Station</th>
          <th scope="col">Dernière communication valide</th>
          <th scope="col">Dernière communication en erreur</th>
          <th scope="col">Statut</th>
        </tr>
      </thead>
      <tbody #stationLastCommunicationRetrieved>
        <tr class="table-{{station.class}}" *ngFor="let station of stations">
          <th scope="row">{{ station.key }} <i *ngIf="station.is_manual" class="bi bi-person-fill-gear fs-4"
              data-bs-toggle="tooltip" data-bs-placement="top" title="Mis à jour manuellement"></i></th>
          <td>{{station.last_valid_communication}}</td>
          <td>{{station.last_error_communication}}</td>
          <td>{{station.statut}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #waitingForData>
  <div class="d-flex justify-content-evenly align-items-center">
    <div class="spinner-border text-success" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>