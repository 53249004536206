<div class="d-flex flex-column gap-3">
  <div class="d-flex justify-content-center">
    <h1>Information sur les sondes en date du {{currentDate}}</h1>
  </div>
  <div class="d-flex justify-content-evenly  align-items-center">
    <div>
      <button type="button" (click)="goToPreviousDate()" class="btn btn-primary">Jour précédent</button>
    </div>
    <div class="d-flex flex-column justify-content-center gap-1">
      <input type="date" class="form-control" (input)="onChangeSelectedDatetime($event)" [value]="selectedDate" />
      <button type="button" (click)="refreshStationsDaily()" class="btn btn-primary">Jour
        sélectionné</button>
    </div>
    <div>
      <button type="button" (click)="goToNextDate()" class="btn btn-primary">Jour suivant</button>
    </div>
  </div>
  <div>
    <h3>Légende :</h3>
    <ul class="d-flex flex-column gap-3 list-unstyled">
      <li><img class="img-size-24" src="assets/images/green-circle.png" /> Donnée OK</li>
      <li><img class="img-size-24" src="assets/images/red-circle.png" /> Donnée hors domaine [-40 ; +40]</li>
      <li><img class="img-size-24" src="assets/images/grey-circle.png" /> Pas de donnée</li>
    </ul>
  </div>
  <div *ngIf="isDataRetrieved; else waitingForData">
    <div class="table-responsive overflow-auto">
      <table class="table table-hover table-bordered align-middle table-sm">
        <thead>
          <tr class="table-primary">
            <th scope="col" class="sticky-top">Station</th>
            <th scope="col" class="sticky-top" *ngFor="let timecode of timeline">{{ timecode }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let stationDailyMonitoring of stationsDailyMonitoring | keyvalue">
            <th scope="row">{{ stationDailyMonitoring.key }}</th>
            <td #stationContentRetrieved *ngFor="let stationContent of $any(stationDailyMonitoring).value"
              data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="bottom"
              title="{{ stationContent.tooltip }}">
              <div class="d-flex justify-content-center">
                <img class="img-size-24" src="assets/images/{{ stationContent.availability }}-circle.png" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ng-template #waitingForData>
    <div class="d-flex justify-content-evenly  align-items-center">
      <div class="spinner-border text-success" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>