<div class="accordion" id="stationsMonitoringAccordion">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
        aria-expanded="true" aria-controls="collapseOne">
        Dernières communications des stations
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
      data-bs-parent="#stationsMonitoringAccordion">
      <div class="accordion-body">
        <app-stations-last-communication></app-stations-last-communication>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        Tableau journalier
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
      data-bs-parent="#stationsMonitoringAccordion">
      <div class="accordion-body">
        <app-stations-daily-monitoring></app-stations-daily-monitoring>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTree">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
        aria-expanded="true" aria-controls="collapseThree">
        Export données d'une station par date
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree"
      data-bs-parent="#stationsMonitoringAccordion">
      <div class="accordion-body">
        <app-stations-by-date-range></app-stations-by-date-range>
      </div>
    </div>
  </div>
</div>