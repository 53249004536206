import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router'
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { StationsDailyMonitoringComponent } from './stations-daily-monitoring/stations-daily-monitoring.component';
import { StationsLastCommunicationComponent } from './stations-last-communication/stations-last-communication.component';
import { StationsByDateRangeComponent } from './stations-by-date-range/stations-by-date-range.component';


const routes: Routes = [
  { path: '', component: AppComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    StationsDailyMonitoringComponent,
    StationsLastCommunicationComponent,
    StationsByDateRangeComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
