<div class="d-flex justify-content-center">
  <h1>Export CSV des données d'une sonde par date</h1>
</div>
<div class="container-date-range">
  <div class="d-flex justify-content-evenly  align-items-end">
    <div class="d-flex flex-column justify-content-center gap-1">
      <label for station>Choisir une station</label>
      <select formControlName="station" class="form-control" (input)="onChangeStation($event)">
        <option id="default">Station...</option>
        <option *ngFor="let station of stationsList">{{station}}</option>
      </select>
    </div>
    <div class="d-flex flex-column justify-content-center gap-1">
      <label for startDate>Date de début</label>
      <input type="date" class="form-control" (input)="onChangeStartDatetime($event)" [value]="startDate" />
    </div>
    <div class="d-flex flex-column justify-content-center gap-1">
      <label for endDate>Date de fin</label>
      <input type="date" class="form-control" (input)="onChangeEndDatetime($event)" [value]="endDate" />
    </div>

    <div class="d-flex flex-column justify-content-center gap-1">
      <button [disabled]="!canExport" type="button" (click)="getDatas()"
        class="btn btn-primary form-control">Exporter</button>
    </div>
  </div>

  <div class="spacer-date-range"></div>
  <!-- <a href={{linkToFile}} target="_blank"><img class="img-size-24" src="assets/images/csv.png" />Télécharger</a> -->

  <div class="table-responsive" *ngIf="isLinkReceived">
    <table class="table table-hover table-bordered align-middle table-sm">
      <thead>
        <tr>
          <th scope="col">Station</th>
          <th scope="col">Date début</th>
          <th scope="col">Date fin</th>
          <th scope="col">Téléchargement</th>
        </tr>
      </thead>
      <tbody #stationLastCommunicationRetrieved>
        <tr *ngFor="let download of downloads">
          <th>{{ download.station }}</th>
          <td>{{ download.startDate }}</td>
          <td>{{ download.endDate }}</td>
          <td><a *ngIf=isLinkReceived href={{download.linkToFile}} target="_blank"><img class="img-size-24"
                src="assets/images/csv.png" />Télécharger</a></td>

        </tr>
      </tbody>
    </table>
  </div>
</div>