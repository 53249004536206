import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { StationsService } from '../stations.service';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-stations-by-date-range',
  templateUrl: './stations-by-date-range.component.html',
  styleUrls: ['./stations-by-date-range.component.sass']
})
export class StationsByDateRangeComponent implements OnInit {

  isStationsRetrieved: boolean = false;
  isLinkReceived: boolean = false;
  currentDatetime = new Date();
  startDatetime = new Date();
  endDatetime = new Date();
  currentDate: string = "";
  startDate: string = "";
  endDate: string = "";
  stationId: string = "";
  datePipe: any;
  stationsList: string[] = [];
  canExport: Boolean = false;
  linkToFile: any;
  downloads: any = [];

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private stationsService: StationsService, @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit(): void {
    this.refreshStationsList();
  }

  initDates() {
    this.currentDate = this.datePipe.transform(this.currentDatetime, 'yyyy-MM-dd')!;
  }

  refreshStationsList() {
    // Abort all previous requests
    this.ngUnsubscribe.next();
    this.isStationsRetrieved = false;
    this.stationsService.fetchStationsList()
      .subscribe(stationsList => {
        this.stationsList = stationsList
      })
    this.isStationsRetrieved = true;
  }

  onChangeStartDatetime(event: any) {
    console.log(event);
    this.startDate = event.target.value;
    this.startDatetime = event.target.valueAsDate;
    this.canExport = this.checkInputValidity();
  }

  onChangeEndDatetime(event: any) {
    console.log(event);
    this.endDate = event.target.value;
    this.endDatetime = event.target.valueAsDate;
    this.canExport = this.checkInputValidity();
  }

  onChangeStation(event: any) {
    console.log(event);
    this.stationId = event.target.value;
    this.canExport = this.checkInputValidity();
  }

  checkInputValidity() {
    return !!this.stationId && this.stationId != "Station..." && !!this.startDate && !!this.endDate && this.startDatetime.getTime() < this.endDatetime.getTime()
  }

  getDatas() {
    this.isLinkReceived = false;
    this.stationsService.fetchStationByDate(this.stationId, this.startDate, this.endDate)
      .subscribe(linkToFile => {
        this.linkToFile = linkToFile;
        this.downloads.push({ station: this.stationId, startDate: this.startDate, endDate: this.endDate, linkToFile: this.linkToFile })
        this.isLinkReceived = true;
      })
  }
}
