import { AfterViewInit, Component, Inject, LOCALE_ID, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StationsService } from '../stations.service';

declare var bootstrap: any;

@Component({
  selector: 'app-stations-daily-monitoring',
  templateUrl: './stations-daily-monitoring.component.html',
  styleUrls: ['./stations-daily-monitoring.component.sass']
})
export class StationsDailyMonitoringComponent implements OnInit, AfterViewInit {
  isDataRetrieved: boolean = false;
  stationsDailyMonitoring: any = {};
  timeline: any = [];
  currentDatetime = new Date();
  selectedDatetime = new Date();
  currentDate: string = "";
  selectedDate: string = "";
  datePipe = new DatePipe(this.locale);
  protected ngUnsubscribe: Subject<void> = new Subject<void>();


  @ViewChildren('stationContentRetrieved')
  stationContent!: QueryList<any>;

  constructor(private stationsService: StationsService, @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit() {
    this.generateTimeLine();
    this.initDates();
    this.refreshStationsDaily();
  }

  ngAfterViewInit() {
    this.stationContent.changes.subscribe(t => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        new bootstrap.Tooltip(tooltipTriggerEl)
      })
    })
  }

  initDates() {
    this.currentDate = this.datePipe.transform(this.currentDatetime, 'yyyy-MM-dd')!;
    this.selectedDate = this.currentDate;
  }

  refreshStationsDaily() {
    // Abort all previous requests
    this.ngUnsubscribe.next();

    this.isDataRetrieved = false;
    this.currentDate = this.selectedDate;
    this.currentDatetime = this.selectedDatetime;
    this.stationsService.fetchStationsDaily(this.currentDate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any[]) => {

        Object.entries(response).forEach(
          ([key, value]) => {
            this.stationsDailyMonitoring[key] = []
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              const tooltip = "Températures : " + element.temperature + "<br>" +
                "Voltage : " + element.voltage + "<br>" +
                "sensor-5cm : " + element.sensor_0 + "<br>" +
                "sensor+0cm : " + element.sensor_1 + "<br>" +
                "sensor+30cm : " + element.sensor_2 + "<br>" +
                "sensor+200cm : " + element.sensor_3

              this.stationsDailyMonitoring[key].push({
                "tooltip": tooltip,
                "availability": element.availability
              })
            }
          }
        );
        console.log(this.stationsDailyMonitoring);
        this.isDataRetrieved = true;
      })
  }

  generateTimeLine() {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j = j + 15) {
        const timecode = String(i).padStart(2, '0') + ":" + String(j).padStart(2, '0');
        this.timeline.push(timecode);
      }
    }
  }

  onChangeSelectedDatetime(event: any) {
    console.log(event);
    this.selectedDate = event.target.value;
    this.selectedDatetime = event.target.valueAsDate;

  }

  goToNextDate() {
    this.selectedDatetime.setDate(this.currentDatetime.getDate() + 1);
    this.selectedDate = this.datePipe.transform(this.selectedDatetime, 'yyyy-MM-dd')!;
    this.refreshStationsDaily()
  }

  goToPreviousDate() {
    this.selectedDatetime.setDate(this.currentDatetime.getDate() - 1);
    this.selectedDate = this.datePipe.transform(this.selectedDatetime, 'yyyy-MM-dd')!;
    this.refreshStationsDaily()
  }
}
