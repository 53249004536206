import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { StationsService } from './stations.service';
declare var bootstrap: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent { }
