import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class StationsService {

  private REST_API = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) { }

  fetchStationsDaily(selectedDate: string): Observable<any[]> {
    return this.httpClient.get<any[]>(this.REST_API + "/daily/" + selectedDate);
  }

  fetchStationsLastCommunication(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.REST_API + "/last-communication");
  }

  fetchStationsList(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.REST_API);
  }

  fetchStationByDate(stationId: string, startDate: string, endDate: string): Observable<any[]> {
    return this.httpClient.get<any[]>(this.REST_API + "/" +stationId + "?startDate=" + startDate + "&endDate=" + endDate);
  }
  
}
