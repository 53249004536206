import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { StationsService } from '../stations.service';

declare var bootstrap: any;

@Component({
  selector: 'app-stations-last-communication',
  templateUrl: './stations-last-communication.component.html',
  styleUrls: ['./stations-last-communication.component.sass']
})
export class StationsLastCommunicationComponent implements OnInit, AfterViewInit {
  isDataRetrieved: boolean = false;
  stations: any = [];
  errorStations: any = [];
  inactiveStations: any = [];
  unknownStations: any = [];
  successStations: any = [];

  @ViewChildren('stationLastCommunicationRetrieved')
  stationContent!: QueryList<any>;

  constructor(private stationsService: StationsService) { }

  ngOnInit(): void {
    this.refreshStationsLastCommunication();
  }

  ngAfterViewInit() {
    this.stationContent.changes.subscribe(t => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        new bootstrap.Tooltip(tooltipTriggerEl)
      })
    })
  }

  getErrorStatus(communication: any): string {
    const { last_valid_communication, last_error_communication } = communication;
    let reason = '';

    if (last_valid_communication === null) reason = "Aucune donnée valide reçue";
    else if (Date.parse(last_error_communication.replaceAll('/', '-')) > Date.parse(last_valid_communication.replaceAll('/', '-'))) reason = "La dernière donnée reçue est invalide";

    return `Erreur (Motif: ${reason})`;
  }

  refreshStationsLastCommunication() {
    this.isDataRetrieved = false;
    this.stationsService.fetchStationsLastCommunication()
      .subscribe((response: any[]) => {
        console.log(response);

        const orderedList = Object.entries(response).sort((a: any, b: any) => a[1].station_state.localeCompare(b[1].station_state));
        orderedList.forEach(
          ([key, value]) => {
            switch (value.station_state) {
              case "error":
                value.class = "danger";
                value.statut = this.getErrorStatus(value);
                this.errorStations[key] = value;
                break;
              case "inactive":
                value.class = "warning";
                value.statut = "Inactif";
                this.inactiveStations[key] = value;
                break;
              case "unknown":
                value.class = "secondary";
                value.statut = "Inconnu";
                this.unknownStations[key] = value;
                break;
              case "valid":
                value.class = "success";
                value.statut = "Valide";
                this.successStations[key] = value;
                break;
              default:
                break;
            }
            this.stations.push({ ...value, key });
          }
        );
        this.isDataRetrieved = true;
      })
  }

}
